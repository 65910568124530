<template>

  <div class=" fxt-template-layout">
    <div :style="{backgroundImage:`url(${shapeImg})`}" class="fxt-shape">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="fxt-column-wrap">
            <div class="fxt-animated-img">
              <img :src="bgImg">
            </div>
            <div class="">
              <a class="fxt-logo" href="#"><img :src="loginImg" alt="Logo"></a>
            </div>
            <div class="">
              <div class="fxt-middle-content">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="fxt-column-wrap justify-content-center">
            <div class="">
              <h4 class="fxt-page-title">欢迎 Welcome</h4>
              <p class="fxt-description">Log In to try our amazing servuces</p>
            </div>
            <div class="form-group">
              <input v-model="form.username" class="form-control" placeholder="请输入用户名"
                     type="text"
                     @keydown="addByEnterKey">
            </div>
            <div class="form-group">
              <input v-model="form.password" class="form-control" placeholder="请输入密码"
                     type="password"
                     @keydown="addByEnterKey">
            </div>
            <div class="form-group">
              <button id="login" class="btn btn-primary  fxt-btn-fill" @click="login">登 录</button>
            </div>
            <div class="form-group register">
              <router-link :to="{path:'/register'}">
                注册用户
              </router-link>
              <router-link :to="{path:'/resetpassword'}">
                忘记密码
              </router-link>
            </div>
            <div class="text-center">
              <p style="font-size: 16px;">Copyright © {{ new Date().getFullYear() }} ONE ORG</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>


import store from '@state/store'

let captcha
export default {
  data() {
    return {
      loginImg: require('../../../../assets/images/logo.svg'),
      bgImg: require('../../../../assets/images/bg34-1.png'),
      shapeImg: require('../../../../assets/images/shape1.png'),
      form: {
        username: '',
        password: ''
      }
    }
  },
  store,
  methods: {
    login() {

      console.log(captcha)

      this.$axios.post('/Login', this.form).then((res) => {
        if (res.data.code === 2001) {
          this.$store.commit('logout')
          this.$message({
            message: res.data.msg,
            type: res.data.code === 1001 ? 'success' : 'warning'
          })

        } else {
          // this.$router.push({name: 'Dashboard'})
          this.$store.commit('login')
          this.$router.push('/')
        }
      })
    },
    addByEnterKey(e) {
      //Enter键的代码就是13
      if (e.keyCode == 13) {
        this.login();
      }
    }
  },
  created() {
    this.init()
  }
}

// eslint-disable-next-line no-unused-vars
function aaa(ss) {
  alert(ss)
  return true
}
</script>

<style scoped>
.fxt-shape {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.fxt-shape img {
  height: auto;
  width: 100%;
}

.form-group {
  margin-bottom: 35px;
}

.form-control {
  border-radius: 10px;
  background-color: #ebf0f6;
  min-height: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ebf0f6;
  padding: 10px 20px;
  color: #111;
}

.fxt-template-layout {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.fxt-template-layout .fxt-animated-img {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
}

.col-lg-8, .col-lg-4 {
  position: relative;
}


.fxt-column-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.fxt-logo {
  display: block;
  margin-bottom: 50px;
  max-width: 35vw;
}

.fxt-logo img {
  max-width: 100%;
  height: auto;
  height: 80px;
}

.fxt-main-title {
  max-width: 280px;
  width: 100%;
  line-height: 60px;
  font-size: 40px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 20px;
}

.fxt-btn-fill {
  font-size: 18px;
  font-weight: 500;
  padding: 12px 36px;
  width: 100%;
  background-color: #00AF92;
  border-color: #00AF92;

}

.fxt-page-title {
  margin-bottom: 15px;
  font-weight: 700;
  color: #14133b;
}

.fxt-description {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #646464;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.register {
  text-align: center;
  font-size: 16px;
}

.register a {
  color: #00AF92;
}

.register a:last-child {
  margin-left: 55px;
}

</style>